import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Login from './Login';
import registerServiceWorker from './registerServiceWorker';

import reactLogo from './assets/img/react-logo.svg';
import ovLogo from './assets/img/logo.png';
import ovLogo2 from './assets/img/logo_classroom.png';

ReactDOM.render(
    <div>
       
        
        <Login />{' '}
    </div>,

    document.getElementById('root'),
);
registerServiceWorker();
