import React from "react";
import App from './App';
import Draw from './Draw';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


export default function Login() {
  return (
    <Router>
      <div>
        <Switch>
     
          <Route path="/draw">
            <Draw />
          </Route>
          <Route path="/">
            <App />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
