import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import OpenViduSession from 'openvidu-react';
import ovLogo2 from './assets/img/logo_classroom.png';
// import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ReactMediaRecorder } from "react-media-recorder";
import { OpenVidu } from 'openvidu-browser';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };
  let displayMediaOptions = {video: true, audio: true};
class App extends Component {
    constructor(props) {
        super(props);
        this.OPENVIDU_SERVER_URL = 'https://ov-server.edecofy.com:4443';
        this.authCheckUrl="https://www.edecofy.com/classroom_login";
        this.OPENVIDU_SERVER_SECRET = 'MY_SECRET';
        this.state = {
            myUserName:  Math.floor(Math.random() * 100),
            token: undefined,
            mySessionId:'',
            userErr:'',
            showErrMsg:false,
            openAttendeesList:false,
            attendeseList:[],
            userPassword:'',
            isPublisher:false,
            totalList:[],
            endByPublisher:false,
            record:false,
            sessionObj:null
        };

        this.handlerJoinSessionEvent = this.handlerJoinSessionEvent.bind(this);
        this.handlerLeaveSessionEvent = this.handlerLeaveSessionEvent.bind(this);
        this.handlerErrorEvent = this.handlerErrorEvent.bind(this);
        this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
        this.handlephonenumberChange=this.handlephonenumberChange.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.joinSession = this.joinSession.bind(this);
        this.handlepasswordChange=this.handlepasswordChange.bind(this);
        this.joinSubscriberSession=this.joinSubscriberSession.bind(this);
        this.isSessionActive=this.isSessionActive.bind(this);
        this.endSession=this.endSession.bind(this);
        this.startRecording=this.startRecording.bind(this);
        
    }

    handlerJoinSessionEvent() {
        console.log('Join session');
    }

    handlerLeaveSessionEvent() {
        if(this.state.isPublisher){
            this.endSession()
        }
        this.setState({
            session: undefined,
        });
    }



    handlerErrorEvent() {
        console.log('Leave session');
    }

    handleChangeSessionId(e) {
        this.setState({
            mySessionId: e.target.value,
        });
    }

    handleChangeUserName(e) {
        this.setState({
            myUserName: e.target.value,
        });
    }
    handlephonenumberChange(e){
        this.setState({
            mySessionId: e.target.value,
        });
        
    }
    handlepasswordChange(e){
        this.setState({userPassword: e.target.value})
    }

    startRecording(){
        if(this.state.record){
            
            let $ele=document.getElementById("stopRecord")
            let $linkele=document.getElementById("recordLink")
  
            $ele && $ele.click();
            setTimeout(()=>{
                console.log($linkele)
                $linkele && $linkele.click()
                this.setState({record:!this.state.record})
            },1000)
            

        }
        else{
        this.setState({record:!this.state.record},()=>{
            if(this.state.record===true){
             
                const id = setInterval(()=>{
                    let $ele=document.getElementById("startRecord");
                    if($ele!==null){
                        $ele.click();                        
                        clearInterval(id);                    
                    }                    
                }, 1000)
            }
         
        })
    }
       // this.startrecord(5000);
    }

   
    endSession(){
        for(let con of this.state.totalList){
            axios.delete(`${this.OPENVIDU_SERVER_URL}/api/sessions/${this.state.mySessionId}/connection/${con.connectionId}`,
            {headers: {
                Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
                'Content-Type': 'application/json',
            }})
               .then((response) => {                 
                   if(response.data!==null){
                       
                   }else{
                       
                       this.setState({userErr:response.data.message,isPublisher:true})
                   }
               })
               .catch((error) => {console.log(error); });
         
       
        }
    }
     chekAuth () {
  
        return new Promise((resolve, reject) => {
          
              let formData = new FormData();
              formData.append('mobile', this.state.mySessionId);
              formData.append('password',this.state.userPassword)
             axios.post(this.authCheckUrl, formData)
                .then((response) => {                 
                    if(response.data.status===true){
                        resolve(response.data.status)                        
                    }else{
                        resolve(response.data.status)
                        this.setState({userErr:response.data.message,isPublisher:false})
                    }
                })
                .catch((error) => {console.log(error); reject(error)});
          
        });
    }
    isSessionActive(){
        return new Promise((resolve, reject) => {
         
          
            axios.get(`${this.OPENVIDU_SERVER_URL}/api/sessions/${this.state.mySessionId}`,
            {headers: {
                Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
                'Content-Type': 'application/json',
            }})
               .then((response) => {                 
                   if(response.data!==null){
                       resolve(response.data)
                   }else{
                       resolve(response.data.status)
                       this.setState({userErr:response.data.message,isPublisher:true})
                   }
               })
               .catch((error) => {
                this.setState({showErrMsg:true,userErr:'this session is not started yet!, Please wait...'});
                   console.log(error); 
                   reject(error);
                });
         
       });
   }
     async joinSession(event) {
       
        this.setState({showErrMsg:false})
        event.preventDefault()
        if (this.state.mySessionId && this.state.myUserName && this.state.mySessionId) {
            if(this.phonenumber(this.state.mySessionId)){
            const response = await this.chekAuth()
            
            if(response){
                this.OV = new OpenVidu();        
                this.setState(
                    {
                        session: this.OV.initSession(),
                    },
                    () => {
                        var mySession = this.state.session;
                    
                        mySession.on('reconnecting', () => alert("you are disconnected from the Edecofy trying to reconnect You"));
                        mySession.on('reconnected', () => alert('Hurray! You successfully reconnected to the session'));
                        mySession.on('sessionDisconnected', (event) => {
                          if (event.reason === 'networkDisconnect') {
                          alert('You lost your connection to the session');
                          } else {
                             // Disconnected from the session for other reason than a network drop
                           }
                      });
                    
                        this.getToken()
                            .then((token) => {
                                mySession
                                    .connect(token, { clientData: this.state.myUserName })
                                    .then(() => {
                                            this.setState({isPublisher:true})
                                    })
                                    .catch((error) => {
                                        console.log('There was an error connecting to the session:', error.code, error.message);
                                    });
                            })
                            .catch((error) => console.log('Error', error));
                    },
                );
        }
        else{
            this.setState({showErrMsg:true})
         }
    }
            
        }
    }

    async joinSubscriberSession(event){
        this.setState({showErrMsg:false})
        event.preventDefault()
        if (this.state.mySessionId && this.state.myUserName && this.state.mySessionId) {
            if(this.phonenumber(this.state.mySessionId)){
            const response = await this.isSessionActive();
            console.log(response)
            if(response){
                this.OV = new OpenVidu();

                this.setState(
                    {
                        session: this.OV.initSession(),
                    },
                    () => {
                        var mySession = this.state.session;
                      
                        mySession.on('reconnecting', () => alert("you are disconnected from the Edecofy trying to reconnect You"));
                        mySession.on('reconnected', () => alert('Hurray! You successfully reconnected to the session'));
                        mySession.on('sessionDisconnected', (event) => {
                          if (event.reason === 'networkDisconnect') {
                          alert('You lost your connection to the session');
                          } else {
                             // Disconnected from the session for other reason than a network drop
                           }
                           
                      });
        
                        this.getToken()
                            .then((token) => {
                             
                                mySession
                                    .connect(token, { clientData: this.state.myUserName })
                                    .then(() => {
                                        this.setState({isPublisher:false})
                                    })
                                    .catch((error) => {
                                        console.log('There was an error connecting to the session:', error.code, error.message);
                                    });
                            })
                            .catch((error) => console.log('Error', error));
                    },
                );
            } else{
                this.setState({showErrMsg:true,userErr:'Please Enter valid mobile number'})
            }
        }
    }
    }
    phonenumber=(inputtxt)=>
            {
            var phoneno = /^\d{10}$/;
            if((inputtxt.match(phoneno)))
                {
                return true;
                    }
                else
                    {
                    this.setState({userErr:'Please Enter valid mobile number',
                    showErrMsg:true})
                    return false;
                    }
            }
            openList=(list)=>{
                let listData=list.map(e=>e.nickname)
                this.setState({openAttendeesList:!this.state.openAttendeesList,attendeseList:listData})
            }
            updateList=(list)=>{
                this.setState({totalList:list})
            }
            streamDestroyed=(args)=>{        
                if(this.state.endByPublisher===true)
                {        
                this.setState({session:undefined})
                }
            }
            removeItem=(item,i)=>{
              
                axios.delete(`${this.OPENVIDU_SERVER_URL}/api/sessions/${this.state.mySessionId}/connection/${item.connectionId}`,
                {headers: {
                    Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
                    'Content-Type': 'application/json',
                }})
                   .then((response) => {             
                       this.setState({totalList:this.state.totalList.splice(i,1)})    
                       if(response.data!==null){
                           
                       }else{
                           
                           this.setState({userErr:response.data.message,isPublisher:true})
                       }
                   })
                   .catch((error) => {console.log(error); });
             
            }

    render() {
        console.log(this.state);
        const mySessionId = this.state.mySessionId;
        const myUserName = this.state.myUserName;
        const token = this.state.token;
        return (
            <div className="row row-centered">
              
                {this.state.session === undefined && sessionStorage.getItem("myAuth")===null ? (
                    <div id="join">
                          <div id="title" className="col-xs-12 col-lg-6 text-center">
                                <a href="https://www.edecofy.com/" target="_blank" rel="noopener noreferrer">
                                    <img src={ovLogo2} className="mainLogo" alt="logo" />
                                </a>
                                {/* <div>
                                    <h3>Contact Us:</h3>
                                    <p>Mobile: +91 8500085454</p>
                                    <p>Email: contact@edecofy.com</p>
                                </div>
                                <div>
                                    <h3>Support</h3>
                                    <p>Mobile: +91 9908817147</p>
                                    <p>Email: support@edecofy.com</p>
                                </div> */}
                            </div>
                            <Tabs className="col-xs-12 col-lg-6 text-center" id="create-join-tabs">
                                <TabList>
                                <Tab>Join Meeting</Tab>
                                <Tab>Create Meeting</Tab>
                                </TabList>
                        
                                <TabPanel>
                                <div id="subscriber-dialog" className="col-xs-12 col-lg-6 text-center">
                                    <h1> Join a video session </h1>
                                    <form onSubmit={this.joinSubscriberSession}>
                                    <div className="form-group">
                                            <input type="text"
                                                id="userName"
                                                className="form-control"
                                                //value={myUserName}
                                                onChange={this.handleChangeUserName}
                                                required placeholder="Enter Your Name" /
                                            >
                                        </div> <div className="form-group">
                                            <input type="number"
                                                id="Mobile_Number"
                                                className="form-control"
                                                //value={mySessionId}
                                                onChange={this.handlephonenumberChange}
                                                pattern="[6789][0-9]{9}"
                                                required placeholder="Enter Mobile Number" /
                                            >
                                        </div>                                      
                                        <div className="form-group">
                                            <input name="commit"
                                            className="btn btn-primary"
                                                type="submit"
                                                value="JOIN" id="subscriber-session" />
                                        </div>
                                        {this.state.showErrMsg && <p>{this.state.userErr}</p>}
                                    </form>
                                </div>
                                </TabPanel>
                                <TabPanel>
                                <div id="join-dialog" className="col-xs-12 col-lg-6 text-center">
                                    <h1> Create a video session </h1>
                                    <form onSubmit={this.joinSession}>
                                    <div className="form-group">
                                            <input type="text"
                                                id="userName"
                                                className="form-control"
                                                //value={myUserName}
                                                onChange={this.handleChangeUserName}
                                                required placeholder="Enter Your Name" /
                                            >
                                        </div> <div className="form-group">
                                            <input type="number"
                                                id="Mobile_Number"
                                                className="form-control"
                                                //value={mySessionId}
                                                onChange={this.handlephonenumberChange}
                                                pattern="[6789][0-9]{9}"
                                                required placeholder="Enter Mobile Number" /
                                            >
                                        </div> 
                                        <div className="form-group">
                                            <input type="password"
                                                id="User_password"
                                                className="form-control"
                                                //value={mySessionId}
                                                onChange={this.handlepasswordChange}                                               
                                                required placeholder="Enter Password" /
                                            >
                                        </div> 
                                        <div className="form-group">
                                            <input name="commit"
                                            className="btn btn-primary"
                                                type="submit"
                                                value="CREATE" id="join-session" />
                                        </div>
                                        {this.state.showErrMsg && <p>{this.state.userErr}</p>}
                                    </form>
                                </div>
                                </TabPanel>
                            </Tabs>
				{/*	<div id="join-dialog" className="col-xs-12 col-lg-6 text-center">
                            <h1> Join a video session </h1>
                            <form onSubmit={this.joinSession}>
                            <div className="form-group">
                                    <input type="text"
                                        id="userName"
                                        className="form-control"
                                        //value={myUserName}
                                        onChange={this.handleChangeUserName}
                                        required placeholder="Enter Your Name" /
                                    >
                                </div> <div className="form-group">
                                    <input type="number"
                                        id="Mobile_Number"
                                        className="form-control"
                                        //value={mySessionId}
                                        onChange={this.handlephonenumberChange}
                                        pattern="[6789][0-9]{9}"
                                        required placeholder="Enter Mobile Number" /
                                    >
                                </div> 
                                <div className="form-group">
                                    <input name="commit"
                                    className="btn btn-primary"
                                        type="submit"
                                        value="JOIN" id="join-session" />
                                </div>
                                {this.state.showErrMsg && <p>{this.state.userErr}</p>}
                            </form>
                            </div>*/}
                        <div className="clear-fix"></div>
                        <div id='support' className="col-lg-12 text-center">
                            <a href="https://www.edecofy.com/contact"
                                target="_blank"
                                rel="noopener noreferrer" >
                                Support and Contact Us
                    </a>
                        </div>
                    </div>
                ) : (
                        <div id="session" >
                            <div id="recordedvideo">
                            <OpenViduSession id="opv-session"
                                sessionName={mySessionId}
                                user={myUserName}
                                token={token}
                                joinSession={this.handlerJoinSessionEvent}
                                leaveSession={this.handlerLeaveSessionEvent}
                                error={this.handlerErrorEvent}
                                attendeseList={this.openList}
                                updateList={this.updateList}
                                streamDestroyed={this.streamDestroyed}
                                startRecording={this.startRecording}
                            /> 
                         {this.state.record && <ReactMediaRecorder
                            screen                            
                            render={({ status, stopRecording, mediaBlobUrl,startRecording }) => (
                                <div>                                    
                                <p>{status}</p> 
                                <button id="startRecord" onClick={startRecording}>Start Recording</button>
                                <button id="stopRecord" onClick={stopRecording}>Stop Recording</button>
                                <a id="recordLink" href={mediaBlobUrl} download={`${new Date().getDate()+"_"+new Date().getMonth()}.mp4`}>Download</a>
                                </div>
                            )}
                            />
                            }
                            </div>
                             {this.state.openAttendeesList ?
                             <Modal 
                             isOpen={true}                             
                            onRequestClose={()=>{this.setState({openAttendeesList:false})}}
                            style={customStyles}
                            contentLabel="Attendeed List"
                             >
                               <div className="attendese-count">
                                   {this.state.isPublisher===true ?  this.state.totalList.map((item,i) => <li key={i}>{item.nickname} <a onClick={()=>{this.removeItem(item,i)}}>remove</a></li>):
                                    this.state.totalList.map((item,i) => <li key={i}>{item.nickname} </li>)
                                   }




                            

                                    </div>
                           </Modal>
                             :null
                            }
                            </div>
                           
                    )
            } </div>
        );
    }

    /**
     * --------------------------
     * SERVER-SIDE RESPONSIBILITY
     * --------------------------
     * These methods retrieve the mandatory user token from OpenVidu Server.
     * This behaviour MUST BE IN YOUR SERVER-SIDE IN PRODUCTION (by using
     * the API REST, openvidu-java-client or openvidu-node-client):
     *   1) Initialize a session in OpenVidu Server	(POST /api/sessions)
     *   2) Generate a token in OpenVidu Server		(POST /api/tokens)
     *   3) The token must be consumed in Session.connect() method
     */

    getToken() {
        return this.createSession(this.state.mySessionId)
            .then((sessionId) => this.createToken(sessionId))
            .catch((Err) => console.error(Err));
    }

    createSession(sessionId) {
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({ customSessionId: sessionId });
            axios
                .post(this.OPENVIDU_SERVER_URL + '/api/sessions', data, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log('CREATE SESION', response);
                    resolve(response.data.id);
                })
                .catch((response) => {
                    var error = Object.assign({}, response);
                    if (error.response && error.response.status === 409) {
                        resolve(sessionId);
                    } else {
                        console.log(error);
                        console.warn(
                            'No connection to OpenVidu Server. This may be a certificate error at ' + this.OPENVIDU_SERVER_URL,
                        );
                        if (
                            window.confirm(
                                'No connection to OpenVidu Server. This may be a certificate error at "' +
                                this.OPENVIDU_SERVER_URL +
                                '"\n\nClick OK to navigate and accept it. ' +
                                'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
                                this.OPENVIDU_SERVER_URL +
                                '"',
                            )
                        ) {
                            window.location.assign(this.OPENVIDU_SERVER_URL + '/accept-certificate');
                        }
                    }
                });
        });
    }

    createToken(sessionId) {
        return new Promise((resolve, reject) => {


            var data = JSON.stringify({ session: sessionId });
            axios
                .post(this.OPENVIDU_SERVER_URL + '/api/tokens', data, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log('TOKEN', response);
                    resolve(response.data.token);
                })
                .catch((error) => reject(error));
        });
    }
}

export default App;